
import { Component, Vue, Prop } from "vue-property-decorator";
import { DateFormatMixin, HeMixin, CurrencyFormatMixin } from "@/mixins";
import { mixins } from "vue-class-component";
import { Sensor } from "@/store/modules";
import { SensorDetailCard } from "./details/SensorDetailCard.vue";
import MetricInfoCard from './details/MetricInfoCard.vue';

@Component({
  components: { SensorDetailCard, MetricInfoCard },
})
export class SensorDetail extends mixins(HeMixin, CurrencyFormatMixin) {
  loading = true;

  sensor: Sensor | null = null;

  @Prop([String, Number])
  readonly id!: string | number;

  mounted() {
    this.$nextTick(() => this.updateData());
  }

  async updateData() {
    let sensor = this.findRecord();
    
    if (!sensor || !sensor.metric) {
      this.loading = true;
      await Sensor.dispatch("findOne", { id: this.id });
      this.sensor = this.findRecord();
    } else {
      this.sensor = sensor;
    }

    this.loading = false;
  }

  findRecord(): Sensor | null {
    return Sensor.query()
      .withAllRecursive()
      .whereId(this.id)
      .first();
  }
}
export default SensorDetail;
