
import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { PartSold, Part } from "@/store/modules";
import { HeMixin, DateFormatMixin } from "@/mixins";
import PartSoldList from '@/pages/part-sold/PartSoldList.vue';
import { TableCardLoader } from "@/components/TableCardLoader.vue";

@Component({
  components: { PartSoldList, TableCardLoader }
})
export class SoldParts extends mixins(HeMixin, DateFormatMixin) {
  @Prop(Part)
  private record!: Part;
  loading = true;

  private partSold?: PartSold[];

  mounted() {
    this.$nextTick(() => this.updateData());
  }

  async updateData() {
    this.loading = true;
    await PartSold.dispatch('findByPart', {
      partId: this.record.id,
      sort: 'created_at',
      order: 'desc'
    })

    this.partSold = this.findRecord();

    this.loading = false;
  }

  findRecord(): PartSold[] {
    return PartSold.query()
      .withAllRecursive()
      .where('part_id', +this.record.id)
      .get() || [];
  }

}

export default SoldParts;
