
import { Component, Vue, Prop } from "vue-property-decorator";
import { PartCategory } from "@/store/modules";
import { mixins } from "vue-class-component";
import { CurrencyFormatMixin, DateFormatMixin } from "@/mixins";
import { InfoCardLoader } from "@/components/InfoCardLoader.vue";

@Component({ components: { InfoCardLoader } })
export class PartCategoryDetailCard extends mixins(CurrencyFormatMixin, DateFormatMixin) {
  @Prop(PartCategory)
  partCategory!: PartCategory;

  loading = false;


}

export default PartCategoryDetailCard;
