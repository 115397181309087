import { render, staticRenderFns } from "./SensorDetailCard.vue?vue&type=template&id=6c4b4612&scoped=true"
import script from "./SensorDetailCard.vue?vue&type=script&lang=ts"
export * from "./SensorDetailCard.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c4b4612",
  null
  
)

export default component.exports