
import { Component, Vue, Prop, Model, Emit } from "vue-property-decorator";
import { DateFormatMixin, HeMixin, CurrencyFormatMixin } from "@/mixins";
import { mixins } from "vue-class-component";
import { Part, PartInterface } from "@/store/modules";
import { PartCategoryDropdown } from "./PartCategoryDropdown.vue";
import jsSHA from 'jssha';



// see https://simonkollross.de/posts/vuejs-using-v-model-with-objects-for-custom-components
@Component({
  components: { PartCategoryDropdown }
})
export class PartForm extends mixins(HeMixin, CurrencyFormatMixin) {
  @Prop({ default: () => ({}) })
  part!: PartInterface;

  wasValidated=false;

  isPrestine = true;

  oriHash: string | null = null;

  mounted() {
    this.oriHash = this.hash(this.part);
  }

  hash(p: PartInterface) {
    const payload = JSON.stringify(p);
    const hash = new jsSHA("SHA-1", 'TEXT');
    hash.update(payload);
    return hash.getHash("HEX");
  }

  partUpdate(key: string, val: string | null) {
    const p = { ...this.part, [key]: val };
    this.isPrestine = this.oriHash === this.hash(p);
    this.$emit("input", p);
  }

  /*onSubmit(e: Event) {
    // todo validation like check for uniques
    this.$emit('submit',e);
  }*/
}
export default PartForm;
