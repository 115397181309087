import { render, staticRenderFns } from "./PartRequestListHandler.vue?vue&type=template&id=48b6c5db"
import script from "./PartRequestListHandler.vue?vue&type=script&lang=ts"
export * from "./PartRequestListHandler.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports