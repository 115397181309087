
import { Component, Watch, Vue, Prop } from "vue-property-decorator";
import { SensorList } from "./SensorList.vue";
import {
  SensorFilterQueryPayload,
  SensorAllPayload,
  Sensor
} from "@/store/modules";
import { CDataTableSortVal } from "@/@types";
import { queryFilterHasChange, filterEmptyQuery } from "@/utility";
import { SortQueryPayload, Manufacturer } from "@/store/modules";
import SensorMap from './map/SensorMap.vue';

@Component({
  components: {
    SensorList,
    SensorMap
  },
})
export default class SensorListHandler extends Vue {
  limit = 25;

  colFilter: SensorFilterQueryPayload = {};

  currentPage = 1;

  sort: SortQueryPayload = {
    sort: "created_at",
    order: "desc",
  };

  sortVal: CDataTableSortVal = {};

  // filterCategories!: SensorCategory[];
  filterManufacturers!: Manufacturer[];

  filterPreset = 0;

  // @Prop(Number)
  // category?: number;

  @Watch("colFilter")
  onColFilterChange(
    val: SensorFilterQueryPayload | null,
    oldVal: SensorFilterQueryPayload | null
  ) {
    if (!val || !oldVal) {
      return;
    }

    if (queryFilterHasChange<SensorFilterQueryPayload>(val, oldVal)) {
      this.load();
    }
  }

  @Watch("limit")
  onLimitChange(val: number, oldVal: number) {
    if (val !== oldVal) {
      this.load();
    }
  }

  @Watch("sortVal")
  onSortValChange(val: CDataTableSortVal, oldVal: CDataTableSortVal) {
    this.sort = {
      sort: val.column || this.sort.sort || "",
      order: val.asc ? "asc" : "desc",
    };
    this.load();
  }

  filterChange(e: any) {
    // switch ("" + e.target.value) {
    //   case "1":

    //     // this.$nextTick(() =>
    //     //   this.fetchRender(
    //     //     this.compilePayload({
    //     //       limit: 999999,
    //     //       filterFn: (sensor) => {
    //     //         const u = sensor.unfulfilled || 0;
    //     //         return u > 0;
    //     //       },
    //     //     })
    //     //   )
    //     // );
    //     // return;
    //     break;

    // }
    const n = Number(e.target.value || 0);
    this.filterPreset = isNaN(n) ? 0 : n;
    this.$nextTick(() => this.fetchRender(this.compilePayload()));
  }

  mounted() {
    this.load();
  }

  load() {
    this.$nextTick(() => this.fetchRender(this.compilePayload()));
  }

  async fetchRender(payload: SensorAllPayload) {
    await Sensor.dispatch("all", payload);
  }

  get items(): Array<any> {
    const data = Sensor.query().withAll().get();
    return data;
  }

  get loading(): boolean {
    return !!Sensor.store().state.entities.sensor.fetching;
  }

  get total() {
    return Sensor.store().state.entities.sensor.total;
  }

  get numPages(): number {
    const total = Sensor.store().state.entities.sensor.total;
    console.log("numPages", total);
    if (!total) {
      return 0;
    }
    // console.log('t', Math.ceil(total / this.limit), 1);
    return Math.max(Math.ceil(total / this.limit), 1);
  }

  onPageChange(page: number) {
    this.$nextTick(() =>
      this.fetchRender(
        this.compilePayload({
          offset: Math.floor(this.limit * (page - 1)),
        })
      )
    );
  }

  onDelete(item: Sensor) {
    const { name } = item;
    const h = this.$createElement;
    const content = h("p", [
      "Are you sure you want to delete this Sensor",
      h("strong", { class: "mx-1" }, name),
      ". ",
      "This action is not reversible.",
    ]);
    this.$bvModal
      .msgBoxConfirm([content], {
        title: "Please Confirm",
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        okTitle: "YES",
        cancelTitle: "NO",
        footerClass: "p-2",
        hideHeaderClose: true,
        centered: true,
      })
      .then((state: boolean) => {
        if (!state) {
          return;
        }

        alert("not supported yet");
      });
  }

  compilePayload(merge: Partial<SensorAllPayload> = {}): SensorAllPayload {
    // console.log('m', merge, {
    //   limit: this.limit,
    //   offset: Math.floor(this.limit * (this.currentPage - 1)),
    //   ...this.sort,
    //   ...{ filter: this.compileFilter() },
    //   ...merge,
    // })
    return {
      limit: this.limit,
      offset: Math.floor(this.limit * (this.currentPage - 1)),
      ...this.sort,
      ...{ filter: this.compileFilter() },
      ...merge,
    };
  }

  compileFilter(): SensorAllPayload["filter"] {
    const f = filterEmptyQuery(this.colFilter);

    switch(this.filterPreset) {
      case 1:
        break;
    }
    return { ...f };
  }

  
}
