
import { Component, Watch, Vue, Prop } from "vue-property-decorator";
import { PartList } from "./PartList.vue";
import {
  PartFilterQueryPayload,
  PartAllPayload,
  Part,
  PartCategory,
} from "@/store/modules";
import { CDataTableSortVal } from "@/@types";
import { queryFilterHasChange, filterEmptyQuery } from "@/utility";
import { SortQueryPayload, Manufacturer } from "@/store/modules";
import PartCategoryMultiDropdown from "../part-category/filters/PartCategoryMultiDropdown.vue";
import ManufacturerMultiDropdown from "../manufacturer/filter/ManufacturerMultiDropdown.vue";

@Component({
  components: {
    PartList,
    PartCategoryMultiDropdown,
    ManufacturerMultiDropdown,
  },
})
export default class PartListHandler extends Vue {
  limit = 25;

  colFilter: PartFilterQueryPayload = {};

  currentPage = 1;

  sort: SortQueryPayload = {
    sort: "created_at",
    order: "desc",
  };

  sortVal: CDataTableSortVal = {};

  filterCategories!: PartCategory[];
  filterManufacturers!: Manufacturer[];

  filterPreset = 0;

  // @Prop(Number)
  // category?: number;

  @Watch("colFilter")
  onColFilterChange(
    val: PartFilterQueryPayload | null,
    oldVal: PartFilterQueryPayload | null
  ) {
    if (!val || !oldVal) {
      return;
    }

    if (queryFilterHasChange<PartFilterQueryPayload>(val, oldVal)) {
      this.load();
    }
  }

  @Watch("limit")
  onLimitChange(val: number, oldVal: number) {
    if (val !== oldVal) {
      this.load();
    }
  }

  @Watch("sortVal")
  onSortValChange(val: CDataTableSortVal, oldVal: CDataTableSortVal) {
    this.sort = {
      sort: val.column || this.sort.sort || "",
      order: val.asc ? "asc" : "desc",
    };
    this.load();
  }

  filterChange(e: any) {
    // switch ("" + e.target.value) {
    //   case "1":

    //     // this.$nextTick(() =>
    //     //   this.fetchRender(
    //     //     this.compilePayload({
    //     //       limit: 999999,
    //     //       filterFn: (part) => {
    //     //         const u = part.unfulfilled || 0;
    //     //         return u > 0;
    //     //       },
    //     //     })
    //     //   )
    //     // );
    //     // return;
    //     break;

    // }
    const n = Number(e.target.value || 0);
    this.filterPreset = isNaN(n) ? 0 : n;
    this.$nextTick(() => this.fetchRender(this.compilePayload()));
  }

  mounted() {
    this.load();
  }

  load() {
    this.$nextTick(() => this.fetchRender(this.compilePayload()));
  }

  async fetchRender(payload: PartAllPayload) {
    await Part.dispatch("all", payload);
  }

  get items(): Array<any> {
    const data = Part.query().withAll().get();
    return data;
  }

  get loading(): boolean {
    return !!Part.store().state.entities.part.fetching;
  }

  get total() {
    return Part.store().state.entities.part.total;
  }

  get numPages(): number {
    const total = Part.store().state.entities.part.total;
    console.log("numPages", total);
    if (!total) {
      return 0;
    }
    // console.log('t', Math.ceil(total / this.limit), 1);
    return Math.max(Math.ceil(total / this.limit), 1);
  }

  onPageChange(page: number) {
    this.$nextTick(() =>
      this.fetchRender(
        this.compilePayload({
          offset: Math.floor(this.limit * (page - 1)),
        })
      )
    );
  }

  onDelete(item: Part) {
    const { name } = item;
    const h = this.$createElement;
    const content = h("p", [
      "Are you sure you want to delete this Part",
      h("strong", { class: "mx-1" }, name),
      ". ",
      "This action is not reversible.",
    ]);
    this.$bvModal
      .msgBoxConfirm([content], {
        title: "Please Confirm",
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        okTitle: "YES",
        cancelTitle: "NO",
        footerClass: "p-2",
        hideHeaderClose: true,
        centered: true,
      })
      .then((state: boolean) => {
        if (!state) {
          return;
        }

        alert("not supported yet");
      });
  }

  compilePayload(merge: Partial<PartAllPayload> = {}): PartAllPayload {
    // console.log('m', merge, {
    //   limit: this.limit,
    //   offset: Math.floor(this.limit * (this.currentPage - 1)),
    //   ...this.sort,
    //   ...{ filter: this.compileFilter() },
    //   ...merge,
    // })
    return {
      limit: this.limit,
      offset: Math.floor(this.limit * (this.currentPage - 1)),
      ...this.sort,
      ...{ filter: this.compileFilter() },
      ...merge,
    };
  }

  compileFilter(): PartAllPayload["filter"] {
    const f = filterEmptyQuery(this.colFilter);

    if (this.filterCategories && this.filterCategories.length) {
      f.category_id = (this.filterCategories || [])
        .map((cat) => cat.id)
        .join(",");
    }

    if (this.filterManufacturers && this.filterManufacturers.length) {
      f.manufacturer = (this.filterManufacturers || [])
        .map((manufacturer) => manufacturer.name)
        .join(",");
    }

    switch(this.filterPreset) {
      case 1:
        // todo: Add filter for unfullilled > 0
        // f.unfulfilled = "'%1%' OR unfulfilled > 0"
        f.unfulfilled = ""
        break;
    }
    return { ...f };
  }

  onCatSelect(options: PartCategory[]) {
    this.filterCategories = options;
    this.load();
  }

  onManufacturerSelect(options: Manufacturer[]) {
    this.filterManufacturers = options;
    this.load();
  }

  
}
