
import { Component, Vue, Prop } from "vue-property-decorator";
import { DateFormatMixin, HeMixin, CurrencyFormatMixin } from "@/mixins";
import { mixins } from "vue-class-component";
import { Part, PartInterface } from "@/store/modules";
import { PartForm } from "./form/PartForm.vue";

@Component({
  components: { PartForm }
})
export class PartEdit extends mixins(HeMixin, CurrencyFormatMixin) {
  loading = true;

  showToast = false;

  toastMessage = "";

  // part: Part | null = null;
  part: PartInterface | null = null;

  @Prop([String, Number])
  readonly id!: string | number;

  mounted() {
    this.$nextTick(() => this.updateData());
    // this.showToast = true;
  }

  async updateData() {
    const part = this.findRecord();
    if (!part) {
      this.loading = true;

      await Part.dispatch("findOne", { id: +this.id });
      const p = this.findRecord();
      if (p) {
        this.part = p.$toJson() as PartInterface;
      }
    } else {
      this.part = part.$toJson() as PartInterface;
    }

    this.loading = false;
  }

  findRecord(): Part | null {
    return Part.query()
      .whereId(+this.id)
      .first();
  }

  async onSubmit(e: Event) {
    e.preventDefault();
    if (!this.part) {
      return;
    }

    // this.showToast = true;
    // this.toastMessage = `Part ${this.part.name} is updating`;
    this.$root.$bvToast.toast(`Part ${this.part.name} is updating`, {
      title: 'Saving',
      variant: 'info'
    });

    let errorMessage: string | null = null;

    try {
      await Part.dispatch("updatePart", { part: this.part });
      await Part.update({
        where: this.part.id,
        data: this.part
      });
    } catch (err) {
      console.error("Error occured", err, this);
      if (typeof err === "string") {
        errorMessage = err;
      }
    }

    if (!this.hasError() && !errorMessage) {
      this.$root.$bvToast.toast(`Part ${this.part.name} has been updated`, {
        title: 'Saving',
        variant: 'success'
      });
      this.$router.push(`/part/${this.part.id}`);
      return;
    }

    this.$root.$bvToast.toast(errorMessage || `Failed to save part`, {
      title: "Error",
      variant: "danger"
    });
  }

  hasError() {
    const errors = this.$store.state.entities.part.error;
    return !(!errors || !errors.length);
  }
}
export default PartEdit;
