
import { Component, Vue, Prop } from "vue-property-decorator";
import { Part } from "@/store/modules";
import { mixins } from "vue-class-component";
import { CurrencyFormatMixin, DateFormatMixin } from "@/mixins";
import { InfoCardLoader } from "@/components/InfoCardLoader.vue";

@Component({ components: { InfoCardLoader } })
export class PartDetailCard extends mixins(CurrencyFormatMixin, DateFormatMixin) {
  @Prop(Part)
  part!: Part;

  loading = false;


}

export default PartDetailCard;
