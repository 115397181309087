
import { CurrencyFormatMixin, HeMixin } from "@/mixins";
import { PartCategoryInterface } from "@/store/modules";
import jsSHA from 'jssha';
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";


// see https://simonkollross.de/posts/vuejs-using-v-model-with-objects-for-custom-components
@Component({
  components: {  }
})
export class PartCategoryForm extends mixins(HeMixin, CurrencyFormatMixin) {
  @Prop({ default: () => ({}) })
  partCategory!: PartCategoryInterface;

  wasValidated=false;

  isPrestine = true;

  oriHash: string | null = null;

  mounted() {
    this.oriHash = this.hash(this.partCategory);
    console.log(this.oriHash);
  }

  hash(p: PartCategoryInterface) {
    const payload = JSON.stringify(p);
    const hash = new jsSHA("SHA-1", 'TEXT');
    hash.update(payload);
    return hash.getHash("HEX");
  }

  partCategoryUpdate(key: string, val: string | null) {
    const p = { ...this.partCategory, [key]: val };
    this.isPrestine = this.oriHash === this.hash(p);
    this.$emit("input", p);
  }

  /*onSubmit(e: Event) {
    // todo validation like check for uniques
    this.$emit('submit',e);
  }*/
}
export default PartCategoryForm;
