
import { Component, Vue, Prop } from "vue-property-decorator";
import { Sensor } from "@/store/modules";
import { mixins } from "vue-class-component";
import { CurrencyFormatMixin, DateFormatMixin } from "@/mixins";
import { InfoCardLoader } from "@/components/InfoCardLoader.vue";

@Component({ components: { InfoCardLoader } })
export class SensorDetailCard extends mixins(
  CurrencyFormatMixin,
  DateFormatMixin
) {
  @Prop(Sensor)
  sensor!: Sensor;

  loading = false;

  get isTektelic(): boolean {
    return this.sensor.provisioner === "tektelic";
  }

  get providerPayload() {
    const s: Partial<Sensor> =
      this && this.sensor ? this.sensor : { providerPayload: null };
    if (!s.providerPayload || !s.providerPayload["tektelic"]) {
      return {};
    }

    return s.providerPayload["tektelic"];
    // return s && s.providerPayload && s.providerPayload["tektelic"]
    //   ? s.providerPayload["tektelic"] || {}
    //   : {};
  }
}

export default SensorDetailCard;
