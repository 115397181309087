
import { Component, Vue, Prop } from "vue-property-decorator";
import { DateFormatMixin, HeMixin, CurrencyFormatMixin } from "@/mixins";
import { mixins } from "vue-class-component";
import { PartCategory } from "@/store/modules";
import { Collection } from "@vuex-orm/core";
import { CSelect } from '@coreui/vue';

@Component({
  components: {}
})
export class PartCategoryDropdown extends mixins(HeMixin, CurrencyFormatMixin) {
  loading = true;

  categoryList: Collection<PartCategory> | PartCategory[] = [];

  // @Prop(CSelect)
  // selectProps!: CSelect;

  @Prop({ default: () => [] })
  readonly categories?: Collection<PartCategory> | PartCategory[];

  mounted() {
    this.$nextTick(() => this.updateData());
  }

  attributes() {
    return {...this.$attrs, options: this.options}
  }

  async updateData() {

    if (this.categories && this.categories.length) {
      this.categoryList = this.categories;
      return;
    }

    this.loading = true;

    await PartCategory.dispatch("all");

    this.categoryList = this.findRecord();

    this.loading = false;
  }

  findRecord(): Collection<PartCategory> {
    return PartCategory.query().get();
  }

  get options() {
    const opt: Array<{ value: string | number | null; label?: string }> = (
      this.categoryList || []
    ).map(cat => ({
      value: cat.id,
      label: `${cat.name}`
    }));

    opt.unshift({
      value: null,
      label: "Choose a category"
    });
    return opt;
  }

  /*onChange(e: Event) {
    console.log('dd onChange', e);
  }*/
}
export default PartCategoryDropdown;
