
import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { PartRequest, Part } from "@/store/modules";
import { HeMixin, DateFormatMixin } from "@/mixins";
import PartRequestList from "@/pages/part-request/PartRequestList.vue";
import { TableCardLoader } from "@/components/TableCardLoader.vue";

@Component({
  components: { PartRequestList, TableCardLoader },
})
export class RequestParts extends mixins(HeMixin, DateFormatMixin) {
  @Prop(Part)
  private record!: Part;
  loading = true;

  // private partRequest?: PartRequest[];

  mounted() {
    this.$nextTick(() => this.updateData());
  }

  async updateData() {
    this.loading = true;
    // await PartRequest.dispatch("findByPart", { partId: this.record.id });
    await PartRequest.dispatch("all", { 
      filter: { part_id: this.record.id } ,
      sort: 'created_at',
      order: 'desc'
    });

    // this.partRequest = this.findRecord();

    this.loading = false;
  }

  get items(): PartRequest[] {
    const data = PartRequest.query()
      .withAll()
      .where("part_id", +this.record.id)
      .get();
    return data || [];
  }

  // get items(): Array<any> {
  //   const data = PartRequest.query()
  //     // .orderBy(this.sort.sort || "created_at", this.sort.order)
  //     .withAll()
  //     .with("part.category")
  //     .get();
  //   return data;
  // }
}

export default RequestParts;
