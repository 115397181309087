
import { Component, Prop, PropSync } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { DateFormatMixin, HeMixin, CurrencyFormatMixin } from "@/mixins";
import { Part, PartFilterQueryPayload } from "@/store/modules";
import { CDataTableFields } from "@/@types";
import PartDeleteButton from "./details/PartDeleteButton.vue";

@Component({
  components: { PartDeleteButton },
})
export class PartList extends mixins(
  DateFormatMixin,
  HeMixin,
  CurrencyFormatMixin
) {
  @Prop({ default: () => [] })
  private items!: Part[];

  @Prop({ default: () => false })
  private loading!: boolean;

  @Prop({ default: () => 0 })
  private total!: boolean;

  @Prop({ default: () => true })
  private itemsPerPageSelect!: boolean;

  @PropSync("itemsPerPage", { default: () => 25, type: Number })
  itemsPerPageSync!: number;

  @PropSync("columnFilterValue", { default: () => ({}), type: Object })
  columnFilterValueSync!: PartFilterQueryPayload;

  @PropSync("sorterValue", { default: () => ({}), type: Object })
  sorterValueSync!: { column: string | null; asc: string | boolean };

  page = 1;

  fields: CDataTableFields = [
    { key: "part_number", label: "Part Number", filter: true, sorter: true },
    { key: "name", label: "Part", filter: true, sorter: true },
    { key: "category", label: "Category", filter: false, sorter: false },
    {
      key: "manufacturer",
      label: "Manufacturer",
      filter: true,
      sorter: true,
    },
    {
      key: "manual_price",
      label: "Manual price",
      filter: false,
      sorter: true,
    },
    // { key: "inventory", label: "QOH", filter: false, sorter: false },
    { key: "unfulfilled", label: "Unfulfilled", filter: false, sorter: true },
    { key: "on_order", label: "On Order", filter: false, sorter: true },

    { key: "public", label: "Public", filter: false, sorter: false },
    { key: "created_at", label: "Created", filter: false, sorter: true },
    { key: "action", label: "", filter: false, sorter: false },
  ];

  onTotalPageChange(val: number) {
    this.itemsPerPageSync = val;
  }

  onColumnFilterChange(val: PartFilterQueryPayload) {
    this.columnFilterValueSync = val;
  }

  onSorterValueChange(val: { column: string; asc: boolean }) {
    this.sorterValueSync = val;
  }

  onRowClick(item: Part) {
    this.$router.push(`/part/${item.id}`);
  }

  onDelete(item: Part) {
    // console.log('hi', item)
    this.$emit("delete", item);
  }
}

export default PartList;
