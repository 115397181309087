
import { Component, Vue, Prop } from "vue-property-decorator";
import { DateFormatMixin, HeMixin, CurrencyFormatMixin } from "@/mixins";
import { mixins } from "vue-class-component";
import { PartCategory, PartCategoryInterface } from "@/store/modules";
import { PartCategoryForm } from "./form/PartCategoryForm.vue";

@Component({
  components: { PartCategoryForm },
})
export class PartCategoryEdit extends mixins(HeMixin, CurrencyFormatMixin) {
  loading = true;

  showToast = false;

  toastMessage = "";

  toastVariant = "info";

  // partCategory: PartCategory | null = null;
  partCategory: PartCategoryInterface | null = null;

  @Prop([String, Number])
  readonly id!: string | number;

  mounted() {
    this.$nextTick(() => this.updateData());
    // this.showToast = true;
  }

  hasError() {
    const errors = PartCategory.state().error;
    return !(!errors || !errors.length);
  }

  async updateData() {
    const partCategory = this.findRecord();
    if (!partCategory) {
      this.loading = true;

      await PartCategory.dispatch("findOne", { id: +this.id });
      const p = this.findRecord();
      if (p) {
        this.partCategory = p.$toJson() as PartCategoryInterface;
      }
    } else {
      this.partCategory = partCategory.$toJson() as PartCategoryInterface;
    }

    this.loading = false;
  }

  findRecord(): PartCategory | null {
    return PartCategory.query()
      .whereId(+this.id)
      .first();
  }

  async onSubmit(e: Event) {
    e.preventDefault();
    if (!this.partCategory) {
      return;
    }
    const cat = this.partCategory;
    this.showToast = true;
    this.toastMessage = `PartCategory ${cat.name} is updating`;

    await PartCategory.dispatch("updatePartCategory", {
      partCategory: this.partCategory,
    });

    await PartCategory.update({
      where: this.partCategory.id,
      data: this.partCategory,
    });

    if (!this.hasError()) {
      this.toastMessage = `PartCategory ${cat.name} has been updated`;
      this.toastVariant = "success";
      // this.showToast = true;
    } else {
      this.toastMessage = `PartCategory failed to update`;
      this.toastVariant = "danger";
    }

    this.$bvToast.show("part-category-form-toast");

    // setTimeout(() => this.$router.push(`/part-category/${cat.id}`), 300)
  }
}
export default PartCategoryEdit;
