
import { Component, Vue, Watch } from "vue-property-decorator";
import {
  ManufacturerAllPayload,
  ManufacturerFilterQueryPayload,
  Manufacturer,
} from "@/store/modules";
import { filterEmptyQuery } from "@/utility";
import Multiselect from "vue-multiselect";

type OptionProps = { text: string; value: string };

@Component({ components: { Multiselect } })
export default class ManufacturerMultiDropdown extends Vue {
  filter: ManufacturerFilterQueryPayload = {};

  // @Model("change", { type: Array, default: () => [] })

  value: OptionProps[] = [];

  // @Model("change", { type: Number, required: false })
  mappedTags: Manufacturer[] = [];

  options: Array<OptionProps> = [];

  optionIndex: Map<string, Manufacturer> = new Map();

  mounted() {
    this.load();
  }

  load() {
    this.$nextTick(() => this.fetchRender(this.compilePayload()));
  }

  async fetchRender(payload: ManufacturerAllPayload) {
    await Manufacturer.dispatch("all", payload);
    this.updateOptions();
  }

  get loading() {
    return !!Manufacturer.store().state.entities.manufacturer.fetching;
  }

  updateOptions(): Array<any> {
    const data = Manufacturer.query().withAll().get();

    this.options = data.map((manufacturer) => ({
      text: "" + manufacturer.name,
      value: "" + manufacturer.name,
    }));

    this.optionIndex = new Map(
      data.map((manufacturer) => [''+manufacturer.name, manufacturer])
    );

    return this.options;
  }

  compilePayload(
    merge: Partial<ManufacturerAllPayload> = {}
  ): ManufacturerAllPayload {
    return {
      ...{ filter: filterEmptyQuery(this.filter) },
      ...merge,
    };
  }

  get availableOptions() {
    // console.log(this.value, "val");
    return this.options;
    // return this.options.filter((opt) => this.value.indexOf(opt.text) === -1);
  }

  onSelect(selectedOption: OptionProps) {
    const { value } = selectedOption;
    const opt = this.optionIndex.get(value);
    if (opt) {
      // handler.change(`${opt.name}`);
      this.mappedTags.push(opt);
      this.$emit("manufacturer-select", this.mappedTags);
    }
  }

  onRemove({ value }: OptionProps) {
    const i = this.mappedTags.findIndex((t) => t.name === value);
    this.mappedTags.splice(i, 1);
    this.$emit("manufacturer-select", this.mappedTags);
  }
}
