
import { Component, Vue, Prop } from "vue-property-decorator";
import { Sensor } from "@/store/modules";
import { mixins } from "vue-class-component";
import { DateFormatMixin } from "@/mixins";
import { InfoCardLoader } from "@/components/InfoCardLoader.vue";
import {
  HomeSensorMeasurements,
  MetricInterface,
} from "../../../store/modules/Sensor/Sensor";

@Component({ components: { InfoCardLoader } })
export class MetricInfoCard extends mixins(DateFormatMixin) {
  @Prop({})
  metric!: MetricInterface;

  loading = false;

  get temp() {
    return HomeSensorMeasurements.TEMPERATURE;
  }

  get bat() {
    return HomeSensorMeasurements.BATTERY;
  }

  get hum() {
    return HomeSensorMeasurements.HUMIDITY;
  }

  get moist() {
    return HomeSensorMeasurements.MOISTURE;
  }

  get tempIcon() {
    const v = +this.metric.val;

    switch(true) {
      case v <= 1:
        return 'thermometer-empty';
      case v <= 18:
        return 'thermometer-quarter';
      case v <= 24:
        return 'thermometer-half';
      case v <= 35:
        return 'thermometer-three-quarters';
      case v >= 36:
        return 'thermometer-full';
    }
    return 'thermometer';
  }
}

export default MetricInfoCard;
