
import { CurrencyFormatMixin, HeMixin } from "@/mixins";
import { PartCategory, PartCategoryInterface } from "@/store/modules";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import { PartCategoryForm } from "./form/PartCategoryForm.vue";

@Component({
  components: { PartCategoryForm }
})
export class PartCategoryAdd extends mixins(HeMixin, CurrencyFormatMixin) {
  // partCategory: PartCategory | null = null;
  partCategory: PartCategoryInterface | null = null;

  toastMessage = "";

  toastVariant = "info";

  mounted() {
    this.$nextTick(() => this.init());
  }

  async init() {
    const p = await PartCategory.new();
    this.partCategory = p.$toJson() as PartCategoryInterface;
    // this.partCategory = p as PartCategory;

  }

  async onSubmit(e: Event) {
    e.preventDefault();
    if(!this.partCategory) {
      return;
    }
    await PartCategory.dispatch("createPartCategory", { partCategory: this.partCategory });
    if (!this.hasError()) {
      this.$root.$bvToast.toast(`Added new category ${this.partCategory.name}`, {
        title: 'Saving',
        variant: 'success'
      });

      this.$router.push('/part-category');
      return;
    }
    this.$root.$bvToast.toast(`Failed to save category`, {
      title: 'Error',
      variant: 'danger'
    });
  }

  hasError() {
    const errors = this.$store.state.entities.partCategory.error;
    return !(!errors || !errors.length);
  }

  // @Watch("partCategory")
  // onPartCategoryChange() {
  //   console.log("test", this.partCategory);
  // }
}
export default PartCategoryAdd;
