
import { CurrencyFormatMixin, HeMixin } from "@/mixins";
import { Part, PartCategory } from "@/store/modules";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import PartList from "../part/PartList.vue";
import { PartCategoryDetailCard } from "./details/PartCategoryDetailCard.vue";

@Component({
  components: { PartCategoryDetailCard, PartList }
})
export class PartCategoryDetail extends mixins(HeMixin, CurrencyFormatMixin) {
  loading = true;

  partCategory: PartCategory | null = null;

  @Prop([String, Number])
  readonly id!: string | number;

  mounted() {
    this.$nextTick(() => this.updateData());
  }

  async updateData() {
    const partCategory = this.findRecord();
    if (!partCategory) {
      this.loading = true;
      await PartCategory.dispatch("findOne", { id: +this.id });

      this.partCategory = this.findRecord();
    } else {
      this.partCategory = partCategory;
    }
    if (this.partCategory) {
      await Part.dispatch("byCategory", { recordId: +this.partCategory.id });
    }

    this.loading = false;
  }

  findRecord(): PartCategory | null {
    return PartCategory.query()
      .whereId(+this.id)
      .first();
  }

  findParts(recordId: number) {
    return Part.query()
      .where("category_id", recordId)
      .get();
  }

  get parts() {
    if (!this.partCategory) {
      return [];
    }

    return this.findParts(+this.partCategory.id);

    // return this.partCategory.parts || [];
  }
}
export default PartCategoryDetail;
