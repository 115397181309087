
import { Component, Prop, PropSync } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { DateFormatMixin, HeMixin, CurrencyFormatMixin } from "@/mixins";
import { Sensor, SensorFilterQueryPayload } from "@/store/modules";
import { CDataTableFields } from "@/@types";

@Component({
  components: {},
})
export class SensorList extends mixins(
  DateFormatMixin,
  HeMixin,
  CurrencyFormatMixin
) {
  @Prop({ default: () => [] })
  private items!: Sensor[];

  @Prop({ default: () => false })
  private loading!: boolean;

  @Prop({ default: () => 0 })
  private total!: boolean;

  @Prop({ default: () => true })
  private itemsPerPageSelect!: boolean;

  @PropSync("itemsPerPage", { default: () => 25, type: Number })
  itemsPerPageSync!: number;

  @PropSync("columnFilterValue", { default: () => ({}), type: Object })
  columnFilterValueSync!: SensorFilterQueryPayload;

  @PropSync("sorterValue", { default: () => ({}), type: Object })
  sorterValueSync!: { column: string | null; asc: string | boolean };

  page = 1;

  fields: CDataTableFields = [
    { key: "name", label: "Name", filter: false, sorter: false },
    { key: "deviceEUI", label: "deviceEUI", filter: false, sorter: false },
    { key: "address", label: "Address", filter: false, sorter: false },
    { key: "action", label: "", filter: false, sorter: false },
  ];

  onTotalPageChange(val: number) {
    this.itemsPerPageSync = val;
  }

  onColumnFilterChange(val: SensorFilterQueryPayload) {
    this.columnFilterValueSync = val;
  }

  onSorterValueChange(val: { column: string; asc: boolean }) {
    this.sorterValueSync = val;
  }

  onRowClick(item: Sensor) {
    this.$router.push(`/sensor/${item.id}`);
  }

  onDelete(item: Sensor) {
    // console.log('hi', item)
    this.$emit("delete", item);
  }
}

export default SensorList;
