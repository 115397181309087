
import { Component, Watch, Vue } from "vue-property-decorator";
import { PartCategoryList } from "./PartCategoryList.vue";
import {
  PartCategoryFilterQueryPayload,
  PartCategoryAllPayload,
  PartCategory,
} from "@/store/modules";
import { CDataTableSortVal } from "@/@types";
import { queryFilterHasChange, filterEmptyQuery } from "@/utility";
import { SortQueryPayload } from "@/store/modules";

@Component({
  components: { PartCategoryList },
})
export default class PartCategoryListHandler extends Vue {
  limit = 25;

  colFilter: PartCategoryFilterQueryPayload = {};

  currentPage = 1;

  sort: SortQueryPayload = {
    sort: "ID",
    order: "desc",
  };

  sortVal: CDataTableSortVal = {};

  @Watch("colFilter")
  onColFilterChange(
    val: PartCategoryFilterQueryPayload | null,
    oldVal: PartCategoryFilterQueryPayload | null
  ) {
    if (!val || !oldVal) {
      return;
    }

    if (queryFilterHasChange<PartCategoryFilterQueryPayload>(val, oldVal)) {
      this.load();
    }
  }

  @Watch("limit")
  onLimitChange(val: number, oldVal: number) {
    if (val !== oldVal) {
      this.load();
    }
  }

  @Watch("sortVal")
  onSortValChange(val: CDataTableSortVal, oldVal: CDataTableSortVal) {
    this.sort = {
      sort: val.column || "",
      order: val.asc ? "asc" : "desc",
    };
    this.load();
  }

  mounted() {
    this.load();
  }

  load() {
    this.$nextTick(() => this.fetchRender(this.compilePayload()));
  }

  async fetchRender(payload: PartCategoryAllPayload) {
    await PartCategory.dispatch("all", payload);
  }

  get items(): Array<any> {
    const data = PartCategory.query().withAll().get();
    return data;
  }

  get loading(): boolean {
    return !!PartCategory.store().state.entities.partCategory.fetching;
  }

  get total() {
    return PartCategory.store().state.entities.partCategory.total;
  }

  get numPages(): number {
    const total = PartCategory.store().state.entities.partCategory.total;

    if (!total) {
      return 0;
    }
    // console.log('t', Math.ceil(total / this.limit), 1);
    return Math.max(Math.ceil(total / this.limit), 1);
  }

  onPageChange(page: number) {
    this.$nextTick(() =>
      this.fetchRender(
        this.compilePayload({
          offset: Math.floor(this.limit * (page - 1)),
        })
      )
    );
  }

  compilePayload(
    merge: Partial<PartCategoryAllPayload> = {}
  ): PartCategoryAllPayload {
    return {
      limit: this.limit,
      offset: Math.floor(this.limit * (this.currentPage - 1)),
      ...this.sort,
      ...{ filter: filterEmptyQuery(this.colFilter) },
      ...merge,
    };
  }

  onDelete(item: PartCategory) {
    const { name, id } = item;
    const h = this.$createElement;
    const content = h("p", [
      "Are you sure you want to delete this part cateogry ",
      h("strong", { class: "mx-1" }, name),
      ". ",
      "This action is not reversible.",
    ]);
    this.$bvModal
      .msgBoxConfirm([content], {
        title: "Please Confirm",
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        okTitle: "YES",
        cancelTitle: "NO",
        footerClass: "p-2",
        hideHeaderClose: true,
        centered: true,
      })
      .then(async (state: boolean) => {
        if (!state) {
          return;
        }

        await PartCategory.dispatch("suspend", { id });

        this.load();
      });
  }
}
