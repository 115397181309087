
import { Component, Prop } from "vue-property-decorator";
import { Part } from "@/store/modules";
import { mixins } from "vue-class-component";
import { PartErrorMixin } from "./PartErrorMixin";

@Component
export class PartDeleteButton extends mixins(PartErrorMixin) {
  @Prop(Part)
  private record!: Part;

  @Prop(Boolean)
  loading = false;

  savingText = "";

  onClick() {
    this.$bvModal
      .msgBoxConfirm(`Are you sure you want to remove ${this.record.name} part?`, {
        title: "Please Confirm",
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        okTitle: "YES",
        cancelTitle: "NO",
        footerClass: "p-2",
        hideHeaderClose: true,
        centered: true,
      })
      .then((state: boolean) => {
        if (!state) {
          return;
        }
        return this.$nextTick(() => this.update());
      });
  }

  async update() {
    // this.loading = true;
    try {
      await Part.dispatch("suspendPart", Number(this.record.id));

      this.$router.push({
        path: "/part",
      });
    } catch (err) {
      console.error("Error occured", err, this);
    }

  }
}

export default PartDeleteButton;
