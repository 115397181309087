
import { CDataTableSortVal } from "@/@types";
import TechnicianMultiDropdown from "@/pages/technician/filters/TechnicianMultiDropdown.vue";
import {
  Manufacturer,
  PartRequest,
  PartRequestAllPayload,
  PartRequestFilterQueryPayload,
  SortQueryPayload,
  Technician,
} from "@/store/modules";
import { filterEmptyQuery, queryFilterHasChange } from "@/utility";
import { Component, Vue, Watch } from "vue-property-decorator";
import ManufacturerMultiDropdown from "../manufacturer/filter/ManufacturerMultiDropdown.vue";
import { PartRequestList } from "./PartRequestList.vue";
import PartCategoryMultiDropdown from "../part-category/filters/PartCategoryMultiDropdown.vue";
import { PartCategory } from '@/store/modules';

@Component({
  components: {
    PartRequestList,
    TechnicianMultiDropdown,
    ManufacturerMultiDropdown,
    PartCategoryMultiDropdown,
  },
})
export default class PartRequestListHandler extends Vue {
  limit = 25;

  colFilter: PartRequestFilterQueryPayload = {};

  currentPage = 1;

  filterFulfilled = 0;

  sort: SortQueryPayload = {
    sort: "created_at",
    order: "desc",
  };

  sortVal: CDataTableSortVal = {
    column: 'created_at', asc: false
  };

  filterTechs!: Technician[];
  filterManufacturers!: Manufacturer[];
  filterCategories!: PartCategory[];

  created() {
    // const update = this.$store.watch<ErrorInterface[]>(
    //   () => PartRequest.getErrors(),
    //   (val, oldVal) => {
    //     if (val && val.length) {
    //       this.$root.$bvToast.toast(`Failed to save Parts request`, {
    //         title: "Error",
    //         variant: "danger",
    //       });
    //     }
    //   }
    // );
    // const saving = this.$store.watch<boolean>(
    //   () => Payment.isSaving(),
    //   (val) => {
    //     this.saving = val;
    //   }
    // );
    // this.subscription = [update, saving];
  }

  @Watch("colFilter")
  onColFilterChange(
    val: PartRequestFilterQueryPayload | null,
    oldVal: PartRequestFilterQueryPayload | null
  ) {
    if (!val || !oldVal) {
      return;
    }

    if (queryFilterHasChange<PartRequestFilterQueryPayload>(val, oldVal)) {
      this.load();
    }
  }

  @Watch("filterFulfilled")
  onFilterFulfilled(
    val: PartRequestFilterQueryPayload | null,
    oldVal: PartRequestFilterQueryPayload | null
  ) {
    if (val === oldVal) {
      return;
    }
    // if (!val || !oldVal) {
    //   return;
    // }

    this.load();
  }

  @Watch("limit")
  onLimitChange(val: number, oldVal: number) {
    if (val !== oldVal) {
      this.load();
    }
  }

  @Watch("sortVal")
  onSortValChange(val: CDataTableSortVal, oldVal: CDataTableSortVal) {
    this.sort = {
      sort: val.column || "",
      order: val.asc ? "asc" : "desc",
    };
    this.load();
  }

  onTechSelect(options: Technician[]) {
    this.filterTechs = options;

    this.load();
  }

  onManufacturerSelect(options: Manufacturer[]) {
    this.filterManufacturers = options;
    this.load();
  }

  onCatSelect(options: PartCategory[]) {
    this.filterCategories = options;
    this.load();
  }

  onRefresh() {
    // this.load();
  }

  mounted() {
    this.load();
  }

  load() {
    this.$nextTick(() => this.fetchRender(this.compilePayload()));
  }

  async fetchRender(payload: PartRequestAllPayload) {
    await PartRequest.dispatch("all", payload);
  }

  get items(): Array<any> {
    const data = PartRequest.query()
      .orderBy(this.sort.sort || "created_at", this.sort.order)
      .withAll()
      .with("part.category")
      .get();
    return data;
  }

  get loading(): boolean {
    return !!PartRequest.store().state.entities.partRequest.fetching;
  }

  get total() {
    return PartRequest.store().state.entities.partRequest.total;
  }

  get numPages(): number {
    const total = PartRequest.store().state.entities.partRequest.total;

    if (!total) {
      return 0;
    }
    // console.log('t', Math.ceil(total / this.limit), 1);
    return Math.max(Math.ceil(total / this.limit), 1);
  }

  onPageChange(page: number) {
    this.$nextTick(() =>
      this.fetchRender(
        this.compilePayload({
          offset: Math.floor(this.limit * (page - 1)),
        })
      )
    );
  }

  compilePayload(
    merge: Partial<PartRequestAllPayload> = {}
  ): PartRequestAllPayload {
    return {
      limit: this.limit,
      offset: Math.floor(this.limit * (this.currentPage - 1)),
      ...this.sort,
      ...{ filter: this.compileFilter() },
      ...merge,
    };
  }

  compileFilter(): PartRequestAllPayload["filter"] {
    const f = filterEmptyQuery(this.colFilter);
    const timeRange = [];
    if (this.colFilter.startDate || this.colFilter.endDate) {
      timeRange.push(this.colFilter.startDate || "");
      timeRange.push(
        this.colFilter.endDate || new Date(Date.now()).toISOString()
      );
      delete f.startDate;
      delete f.endDate;
    }

    if (timeRange.length) {
      f.timeRange = timeRange.join(",");
    }

    if (this.filterTechs && this.filterTechs.length) {
      f.tech_id = (this.filterTechs || []).map((tech) => tech.ID).join(",");
    }

    if (this.filterManufacturers && this.filterManufacturers.length) {
      f.manufacturer = (this.filterManufacturers || [])
        .map((manufacturer) => manufacturer.name)
        .join(",");
    }

    if (this.filterCategories && this.filterCategories.length) {
      f.category_id = (this.filterCategories || [])
        .map((cat) => cat.id)
        .join(",");
    }/* else {
      delete f.category_id
    }*/

    // if (this.filterMR && this.filterMR.length) {
    //   f.mrID = (this.filterMR || []).map((mr) => mr.id).join(",");
    // }
    if (!this.filterFulfilled) {
      f.fulfilled = 0;
    }
    return { ...f };
  }
}
