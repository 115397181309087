
import { Component, Vue, Prop } from "vue-property-decorator";
import { DateFormatMixin, HeMixin, CurrencyFormatMixin } from "@/mixins";
import { mixins } from 'vue-class-component';
import { Part, PartCategory } from '@/store/modules';
import { PartDetailCard } from './details/PartDetailCard.vue';
import { SoldParts } from './details/SoldParts.vue';
import { RequestParts } from './details/RequestParts.vue';
import PartDeleteButton from "./details/PartDeleteButton.vue";

@Component({
  components: {PartDetailCard, SoldParts, RequestParts, PartDeleteButton}
})
export class PartDetail extends mixins(HeMixin, CurrencyFormatMixin) {
  loading = true;

  part: Part | null = null;

  @Prop([String, Number])
  readonly id!: string | number;

  mounted() {
    this.$nextTick(() => this.updateData());
  }

  async updateData() {
    let part = this.findRecord();
     if (!part) {
      this.loading = true;
      await Part.dispatch("findOne", { id: +this.id });
      this.part = this.findRecord();
    } else {
      if (!part.category && part.category_id) {
        await PartCategory.dispatch("findOne", { id: +part.category_id });
        part = this.findRecord();
      }
      this.part = part;
    }
    
    this.loading = false;
  }

  findRecord(): Part | null {
    return Part.query()
      .withAllRecursive()
      .whereId(+this.id)
      .first();
  }
}
export default PartDetail;
