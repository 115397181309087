
import { Component, Vue, Prop } from "vue-property-decorator";
import { DateFormatMixin, HeMixin, CurrencyFormatMixin } from "@/mixins";
import { mixins } from "vue-class-component";
import { PartRequest, Technician } from "@/store/modules";

@Component({
  components: {  }
})
export class PartRequestDetail extends mixins(HeMixin, CurrencyFormatMixin) {
  loading = true;

  partRequest: PartRequest | null = null;

  @Prop([String, Number])
  readonly id!: string | number;

  mounted() {
    this.$nextTick(() => this.updateData());
  }

  print() {
    // console.log(this.$htmlToPaper);
    this.$htmlToPaper('print-reciept');
  }

  async updateData() {
    let partRequest = this.findRecord();
    // if (!partRequest) {
    //   this.loading = true;
    //   await PartRequest.dispatch("findOne", { id: +this.id });
    //   partRequest = this.findRecord();
    //   if (!partRequest) {
    //     this.loading = false;
    //     console.error("Unknown partRequest with id ", this.id);
    //     return;
    //   }
    // }

    // if (!partRequest.technician && partRequest.techID) {
    //   await Technician.dispatch("findOne", {
    //     id: +partRequest.techID
    //   });
    //   partRequest = this.findRecord();
    // }

    // this.partRequest = partRequest;
    this.loading = false;
  }

  findRecord(): PartRequest | null {
    return PartRequest.query()
      .withAllRecursive()
      .whereId(+this.id)
      .first();
  }
}
export default PartRequestDetail;
