
import { Component, Prop, PropSync } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { DateFormatMixin, HeMixin, CurrencyFormatMixin } from "@/mixins";
import { PartCategory, PartCategoryFilterQueryPayload } from "@/store/modules";
import { CDataTableFields } from "@/@types";

@Component({
  components: {},
})
export class PartCategoryList extends mixins(
  DateFormatMixin,
  HeMixin,
  CurrencyFormatMixin
) {
  @Prop({ default: () => [] })
  private items!: PartCategory[];

  @Prop({ default: () => false })
  private loading!: boolean;

  @Prop({ default: () => 0 })
  private total!: boolean;

  @Prop({ default: () => true })
  private itemsPerPageSelect!: boolean;

  @PropSync("itemsPerPage", { default: () => 25, type: Number })
  itemsPerPageSync!: number;

  @PropSync("columnFilterValue", { default: () => ({}), type: Object })
  columnFilterValueSync!: PartCategoryFilterQueryPayload;

  @PropSync("sorterValue", { default: () => ({}), type: Object })
  sorterValueSync!: { column: string | null; asc: string | boolean };

  page = 1;

  fields: CDataTableFields = [
    { key: "name", label: "Name", filter: false, sorter: false },
    // { key: "referenceNumber", label: "Ref", filter : false, sorter: false },
    // { key: "amount", label: "Amount", filter : false, sorter: false },
    // { key: "type", label: "Type", filter : false, sorter: false },
    // { key: "approved", label: "Approved", filter : false, sorter: false  },
    // { key: "voided", label: "Voided", filter : false, sorter: false  },
    { key: "created_at", label: "Made on", filter: false, sorter: false },
    { key: "action", label: "", filter: false, sorter: false },
  ];

  onTotalPageChange(val: number) {
    this.itemsPerPageSync = val;
  }

  onColumnFilterChange(val: PartCategoryFilterQueryPayload) {
    this.columnFilterValueSync = val;
  }

  onSorterValueChange(val: { column: string; asc: boolean }) {
    this.sorterValueSync = val;
  }

  onRowClick(item: PartCategory) {
    this.$router.push(`/part-category/${item.id}`);
  }

  onDelete(cat: PartCategory) {
    this.$emit("delete", cat);
  }
}

export default PartCategoryList;
