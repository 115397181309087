
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { DateFormatMixin, HeMixin, CurrencyFormatMixin } from "@/mixins";
import { mixins } from "vue-class-component";
import { Part, PartInterface } from "@/store/modules";
import { PartForm } from "./form/PartForm.vue";

@Component({
  components: { PartForm }
})
export class PartAdd extends mixins(HeMixin, CurrencyFormatMixin) {
  // part: Part | null = null;
  part: PartInterface | null = null;

  mounted() {
    this.$nextTick(() => this.init());
  }

  async init() {
    const p = await Part.new();
    this.part = p.$toJson() as PartInterface;
    // this.part = p as Part;
  }

  async onSubmit(e: Event) {
    e.preventDefault();
    if(!this.part) {
      return;
    }
    let errorMessage: string | null = null, lastId = null;

    try {
      await Part.dispatch("createPart", { part: this.part });

      lastId = this.getLastInsertedId();

      if (lastId <= 0) {
        throw 'Missing last inserted id';
      }
      
    } catch (err) {
      console.error('Error occured', err, this);
      if(typeof err === 'string') {
        errorMessage = err;
      }
    }
    
    if (!this.hasError() && !errorMessage) {
      this.$root.$bvToast.toast(`Added new part ${this.part.name}`, {
        title: 'Saving',
        variant: 'success'
      });

      this.$router.push(`/part/${lastId}`);
      return;
    }

    this.$root.$bvToast.toast(errorMessage || `Failed to save part`, {
      title: "Error",
      variant: "danger"
    });

  }

  getLastInsertedId() {
    return this.$store.state.entities.part.lastInsertedId;
  }

  hasError() {
    const errors = this.$store.state.entities.part.error;
    return !(!errors || !errors.length);
  }

  // @Watch("part")
  // onPartChange() {
  //   console.log("test", this.part);
  // }
}
export default PartAdd;
